import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import keycloak from '@/utils/keycloak';

keycloak.onReady = (authenticated: boolean) => {

    if (authenticated) {

        store.commit('setAuthenticated', true);
        store.commit('setUser', {"name":"John Doe","email":"john.doe@acme.ltd"});
        store.commit('setToken', JSON.stringify(keycloak.token));

        localStorage.setItem('token', JSON.stringify(keycloak.token));

        createApp(App).use(store).use(router).mount('#app')

    } else {
        keycloak.login();
    }

    console.group("Keycloak");
    console.log('Token expires in', Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000), ' seconds');
    setInterval(() => {
        keycloak.updateToken(70).then((refreshed) => {
            if (refreshed) {
                console.log('Token refreshed', refreshed);
                localStorage.setItem('token', JSON.stringify(keycloak.token));
            } else {
                console.warn('Token not refreshed, valid for ',
                    Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000), ' seconds');
            }
        }).catch(() => {
            console.error('Failed to refresh token.');
        });
    }, 60000);
    console.groupEnd();
}

keycloak.init({onLoad: 'login-required', promiseType: 'native'})
    .then(() => {
        if (!keycloak.authenticated) {
            keycloak.login();
        }
    })
    .catch(() => {
        console.error('Authenticated Failed!');
    });