import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  { path: '/', redirect: '/home' },
  {path: '/splash', name: 'splash', component: () => import(/* webpackChunkName: "splash" */ '@/pages/PageSplash.vue')}, // Splash is a page
  {path: '/home', name: 'home', component: () => import(/* webpackChunkName: "home" */ '@/pages/PageHome.vue')}, // Home is a page
  {path: '/topology', name: 'topology', component: () => import(/* webpackChunkName: "topology" */ '@/pages/PageTopology.vue')},
  {path: '/logout', name:'logout', component: () => import(/* webpackChunkName: "about" */ '@/pages/PageLogout.vue')}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
