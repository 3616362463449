import { createStore } from 'vuex'

export default createStore({
  state: {
    Token: null,
    Authenticated: false,
    User: null,
    GraphData: null,
    GraphDataConfigZoom: null,
    GraphDataConfigPan: null,
    GraphDataConfigLayouts: null,
  },
  getters: {
    getToken: state => state.Token,
    getAuthenticated: state => state.Authenticated,
    getUser: state => state.User,
    getGraphData: state => state.GraphData,
    getGraphDataConfigZoom: state => state.GraphDataConfigZoom,
    getGraphDataConfigPan: state => state.GraphDataConfigPan,
    getGraphDataConfigLayouts: state => state.GraphDataConfigLayouts
  },
  mutations: {
    setToken(state, token) {
      state.Token = token;
    },
    setAuthenticated(state, authenticated) {
        state.Authenticated = authenticated;
    },
    setUser(state, user) {
        state.User = user;
    },
    setGraphData(state, data) {
      state.GraphData = data;
    },
    setGraphDataConfigZoom(state, zoom) {
      state.GraphDataConfigZoom = zoom;
    },
    setGraphDataConfigPan(state, pan) {
      state.GraphDataConfigPan = pan;
    },
    setGraphDataConfigLayouts(state, layouts) {
      state.GraphDataConfigLayouts = layouts;
    }
  },
  actions: {
  },
  modules: {
  }
})
