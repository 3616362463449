/* global appConfig */
import Keycloak from "keycloak-js";

let initOptions = {
    url: appConfig.VUE_APP_KEYCLOAK_URL,
    realm: appConfig.VUE_APP_KEYCLOAK_REALM,
    clientId: appConfig.VUE_APP_KEYCLOAK_CLIENT_ID,
    onLoad: 'check-sso',
    responseType: 'token',
    responseMode: 'query',
    redirectUri: appConfig.VUE_APP_BASE_URL
};

const keycloak = new Keycloak(initOptions);

export default keycloak;